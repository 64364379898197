<template>
  <div :style="{ height: heights }">
    <div class="tab">
      <div>
        <van-icon name="arrow-left" @click="onClickLeft()" v-if="iconShow" />
      </div>
      <div style="display: flex">
        <!-- <van-icon name="star" />  实底收藏 -->
        <div style="display: flex;align-items: center;margin-right:20px;">
          <van-icon
            name="star-o"
            @click="collection"
            v-if="this.collectionType == false"
            style="margin-right: 5px;"
            :class="{ isActive: !userId }"
          />
          <van-icon
            name="star"
            @click="cancelCollection"
            v-if="this.collectionType == true"
            class="shoucang"
            style="margin-right: 5px;"
          />
          <span :class="{ isActive: !userId }">收藏</span>
        </div>
        <div style="display: flex;align-items: center;" @click="routerGoEmail">
          <van-icon
            name="guide-o"
            style="margin-right: 5px;"
            :class="{ isActive: !userId }"
          /><span :class="{ isActive: !userId }">发送到邮箱</span>
        </div>
      </div>
    </div>
    <iframe
      style="z-index: 99999;
        width: 100%"
      :src="url"
      width="100%"
      :height="height"
      frameborder="0"
      ref="iframe"
    >
    </iframe>
    <login v-model="loginShow" :title="loginTitle" />
  </div>
</template>

<script>
// import Vue from "vue";
// import { recordOperation } from "@/utils/myUtils";
import { setById } from "@/service/baseDocument";
import { getAdmins } from "@/utils/adminDate";
import login from "@/components/login.vue";
import {
  postCollection,
  delCollection,
  getIsCollection,
} from "../service/document_api";
export default {
  components: { login },
  name: "PdfVue.vue",
  data() {
    return {
      url: "",
      height: null,
      collectionType: "",
      channel: "",
      version: "",
      beforeUrl: null,
      iconShow: true,
      userId: "",
      loginShow: false,
      loginTitle: "登录后进行操作",
    };
  },

  created() { 
    if(this.$route.query.memberId){
        this.$store.commit('memberIdDocument', this.$route.query.memberId)   
    }
    this.getById();
    let divHeight = window.innerHeight + "px";
    this.heights = divHeight;
    this.url = this.$route.query.url;
    this.$nextTick(() => {
      // document.title = this.$route.query.title
      this.height = window.innerHeight;

      let title = this.$route.query.title;
      let len = title.length; //总长度
      const size = 13; //展示的title长度
      // console.log(size)
      // const rate = 500; //title滚动的频率，最小300ms
      if (len > size) {
        let num = 0;
        let tmpStr = title.substr(num, size);
        document.title = tmpStr + "...";
      } else {
        document.title = title;
      }
    });
    this.getCollection();
    


  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeUrl = from.path;
    });
  },
  mounted() {
   
    this.$nextTick(() => {
      //获取上级路由
      if (this.beforeUrl === "/") {
        this.iconShow = false;
      } else {
        this.iconShow = true;
      }
      console.log(this.iconShow);
    });
  },
  methods: {
    async getById() {
      this.userId = this.$store.state.memberIdDocument;
      console.log("有咩有,", this.userId);
      
      console.log("333", this.userId);
      //像接口头部传用户信息
      let arrays = await getAdmins(
         this.$store.state.memberIdDocument,
        this.$route.query.channel //渠道
      );
       console.log(arrays);
      this.channel = this.$route.query.channel; // 系统
      this.version = arrays.version; //版本
      // this.ip = arrays.ip;
      await setById(arrays);
      console.log(arrays);
      
    },

    // 获取收藏状态
    async getCollection() {
      const data = {
        userId:  this.$store.state.memberIdDocument, //用户id  需要更换
        contentId: this.$route.query.id, //内容id
        contentType: "DOCUMENT", //收藏类型，文献指南，DOCUMENT
      };
      const response = await getIsCollection(data);
      if (response.code === 0) {
        // console.log("状态",response.data)
        if (response.data == 1) {
          this.collectionType = true;
        } else {
          this.collectionType = false;
        }
      } else {
        this.$message.warning(response.message);
      }
    },
    // 收藏
    async collection() {
      console.log("333",this.$store.state.memberId);
      
      if (this.$store.state.memberIdDocument=='') {
        this.loginShow = true;
        return
      }
      const data = {
        userId:  this.$store.state.memberIdDocument, //用户id
        contentId: this.$route.query.id, //内容id
        contentType: "DOCUMENT", //收藏类型，文献指南，DOCUMENT
        channel: this.$route.query.channel, //渠道，查看数据字典
      };
      // console.log("id在不在",data)

      const response = await postCollection(data);
      if (response.code === 0) {
        this.$message.success("收藏成功");
        this.collectionType = true;
        this.getCollection();
      } else {
        this.$message.warning(response.message);
      }
      // 取消了埋点
      // const res = await recordOperation(
      //   this.$route.query.id,
      //   "DOCUMENT",
      //   "DOCUMENT_LIST",
      //   this.channel,
      //   this.$route.query.memberId,
      //   this.version,
      //   this.$route.query.channel
      // );
      // if (res.code === 0) {
      //   // await this.$router.push({
      //   //   path: "/pdfVue",
      //   //   query: { url: "pdf/web/viewer.html?file=" + pdf_url, title: title,id:id ,channel:this.channel},
      //   // });
      //   console.log("埋到了")
      // }
    },
    // 取消收藏（差个差收藏的状态）
    async cancelCollection() {
      // const data={
      //   userId: "88e858b2-e093-4971-8967-2c3dfc7c3715",//用户id
      //   contentId: this.$route.query.id,//内容id
      //   contentType: "DOCUMENT",//收藏类型，文献指南，DOCUMENT
      //   channel: this.$route.query.channel,//渠道，查看数据字典
      // }
      // console.log("id在不在",data)
      const response = await delCollection("DOCUMENT", this.$route.query.id);
      if (response.code === 0) {
        this.$message.success("取消收藏");
        this.collectionType = false;
        this.getCollection();
      } else {
        this.$message.warning(response.message);
      }
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },
    // 去下一页
    routerGoEmail() {
      if (this.$store.state.memberIdDocument=='') {
        this.loginShow = true;
        return
      }
      this.$router.push({
        path: "/SendToEmail",
        query: {
          url: this.$route.query.url,
          title: this.$route.query.title,
          memberId: this.$store.state.memberIdDocument,
          channel: this.$route.query.channel,
          id: this.$route.query.id,
        },
      });
    },
  },
  watch: {
    $route(now, old) {
      console.log(now);
      
      if(now.query!==old.query){
           this.getById()
           this.getCollection()
      }
    },
  },
};
</script>

<style scoped>
* {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}
.tab {
  width: 100%;
  height: 40px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 15px;
  box-sizing: border-box;
}
.shoucang {
  color: orange;
}
.isActive {
  color: rgb(98, 100, 102);
}
</style>
